'use client'

import { Box, ChakraProvider, useColorModeValue, extendTheme, withDefaultColorScheme, theme as baseTheme, ColorModeScript } from "@chakra-ui/react"
import { SessionProvider } from "next-auth/react"
import { AppProgressBar as ProgressBar } from "next-nprogress-bar"
import QueryProvider from "./query"
import { Suspense } from "react"

export default function DefaultProviders({ children }: { children: React.ReactNode }) {
    const customTheme = extendTheme(
        {
            colors: {
                brand: baseTheme.colors.blue
            }
        }
    )
    return (
        <ChakraProvider theme={customTheme}>
            {/* <ColorModeScript initialColorMode={baseTheme.config.initialColorMode} /> */}
            <SessionProvider>
                <QueryProvider>
                    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.800')}>
                        <Suspense>
                            <ProgressBar
                                height="4px"
                                color="#3182CE"
                                options={{ showSpinner: false }}
                                shallowRouting
                            />
                        </Suspense>
                        {children}
                    </Box>
                </QueryProvider>
            </SessionProvider>

        </ChakraProvider>
    )
}